@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

// colors
$brand-color: #0d395f;
$hover-bg: #ecf0f1;

// nav
$nav-height: 53px;

// breadcrumb
$breadcrumb-height: 15px;




.btn {
  box-shadow: none !important;
  border-width: 0 !important;
}

.btn {
  text-transform: none;
  font-size: 13px;
  padding: 8px 12px;
}

@import "../variables";

.map-showing {
  position: relative;
  > .col {
    &:first-child {
      padding-right: 0;
    }
    &:last-child {
      padding-left: 0;
      padding-right: 8px;
      padding-top: 42px;
/*      border: 1px solid #ddd;
      border-left: 0;*/
    }
  }
  .header {
    width: 100%;
    padding-bottom: 18px;
    position: static;
    .icons {
      position: absolute;
      top: 0;
      right: 12px;
      white-space: nowrap;
      z-index: 100;
    }

    .count {
      display: none;
    }
  }

  .grid {
    max-height: 100% !important;
  }

  .infowindow {
    padding: 5px;
    font-family: Montserrat,sans-serif;
    h1 {
      font-size: 16px;
      font-weight: 500;
      color: #666;
    }
  }

  &:not(.map-street-showing) {
    .gm-ui-hover-effect {
      display: none !important;
    }
  }

  &.map-street-showing {
    position: relative;
    .header {
      .icon:not(.active) {
        display: none;
        /*position: absolute;
        left: 50%;*/
      }
      .count {
        display: none;
      }
    }
    .grid {
      display: none;
    }
    > .market.dropdown {
      right: 60px;
    }
    > .row {
      margin-left: 0;
      margin-right: -4px;
    }

    .pano {
      .select {
        display: none;
        top: 8px !important;
        left: 10px !important;
        padding: 5px 13px;
        background-color: rgba(#222, .85);
        color: #ccc;
        box-shadow: none;
        font-size: 15px;
        transition: color 0.2s linear;
        &.show {
          display: block;
        }
        &:hover {
          cursor: pointer;
          color: #fff;
        }
      }
    }

    &.index.venue {
      > .market.dropdown {
        top: 0;
        transform: none;
      }
      > .row {
        > .col {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}

.market.dropdown {
  width: auto;
  position: absolute;
  z-index: 10;
  transform: translateY(-100%);
  top: -2px;
  right: 112px;

  .dropdown-toggle {
    font-size: 12px;
    color: #666;
    background-color: transparent;
    padding-right: 0;
    &:hover {
      color: $brand-color;
    }
  }
  .dropdown-menu {
    font-size: 12px;
    max-height: 400px;
    overflow-y: auto;
    .dropdown-header {
      font-size: 12px;
      color: #888;
    }
    .dropdown-item {
      font-size: 11px;
      color: #777;
      &:hover {
        color: $brand-color;
      }
      &.neighborhood {
        padding-left: 25px;
      }
      &.active {
        color: #000;
        background-color: #f1f1f1;
      }
    }
  }
}

.tab-content {
  .map-showing {
    > .col {
      &:last-child {
        padding-top: 0;
      }
    }
    .header {
      padding-bottom: 0;
      .icons {
        top: -37px;
      }
      .right {
        .fa-location-dot {
          margin-right: 15px;
        }
      }
    }
  }
}

.modal.map-search {
  .modal-body {
    .nav {
      justify-content: center;
      margin: 0 0 12px 0;
      position: relative;
      .market.dropdown {
        top: -3px;
        left: 0;
        right: 100%;
        transform: none;
        width: auto;
        button {
          padding-left: 0;
        }
      }
      .search {
        margin-right: 5px;
        position: relative;
        z-index: 100;
        input {
          border: 1px solid #bdbdbd;
          border-radius: 0.25rem;
          background-color: #fff;
          font-size: 11px;
          line-height: 1.8;
          padding: 3px 10px;
          width: 400px;

          @media (max-width: 1100px) {
            width: 300px;
          }
        }
      }

      .options {
        margin-left: -5px;
        position: relative;
        > .form {
          max-width: 275px;
          form {
            > .container {
              display: flex;
              > .row {
                margin: 0;
                .form-control {
                  width: 65px;
                }
                + .submit {
                  margin-top: 0;
                  margin-left: 10px;
                  .btn {
                    padding: 4px 12px;
                    &[disabled] {
                      padding-right: 30px;
                      position: relative;
                    }

                    .spinner {
                      top: 7px;
                      left: calc(50% + 20px);
                      width: 14px;
                      height: 14px;
                    }
                  }
                }
              }
            }
          }
        }

        .stop {
          color: #888;
          position: absolute;
          top: 2px;
          right: -46px;

          &:hover {
            cursor: pointer;
            color: #000;
          }
        }

        .clear {
          position: absolute;
          top: 0;
          right: -55px;
          .btn {
            padding: 3px 12px;
            background-color: #fcfcfc;
            border: 1px solid #ddd !important;
            &:hover, &:active, &:focus {
              background-color: #f1f1f1;
              color: #333;
              border-color: #ccc !important;
            }
          }
        }
      }


      .alerts {
        position: absolute;
        top: -2px;
        right: 0;
        display: flex;
        gap: .15rem;
        .alert.empty {
          margin-left: 10px;
          border: 0;
          padding: 0 8px;
          background-color: #f2f2f2;
          color: #666;
          font-size: 12px;

          > span {
            display: inline-block;
            color: #bbb;
            margin: 0 10px;
          }
        }
      }
    }

    .results-container {
      position: relative;
      padding-top: 10px;
      &.empty {
        display: none;
      }
    }

    .buttons {
      position: absolute;
      z-index: 999;
      transform: translateX(-50%);
      left: 50%;
      top: -30px;
      display: flex;
      gap: .75rem;
      padding: .65rem;
      background-color: #fff;
      border-radius: 10px;

      button {
        line-height: 1;
        font-size: 11px;
        .fa {
          padding-left: 7px;
        }

        &.select-all {
          background-color: #fcfcfc;
          border: 1px solid #ddd !important;
          &:focus {
            color: #6c757d;
          }
          &:hover {
            background-color: #f1f1f1;
            color: #333;
            border-color: #ccc !important;
          }
        }
      }
    }

    .places-container {
      overflow-x: hidden;
      overflow-y: scroll;
      padding-top: 20px;
      padding-right: 50px;

      .places {
        user-select: none;
        display: grid;
        grid-template-columns: 20% 20% 20% 20% 20%;
        grid-gap: 16px;
        margin-right: 15px;

        .place {
          user-select: none;
          position: relative;

          .photo {
            height: 175px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 10px;
            }
            &.default {
              position: relative;
              $default-image-color: #e6e6e6;
              .fa-image {
                width: 100%;
                height: 100%;
                color: $default-image-color;
                position: relative;
                z-index: 2;
              }
              &:before {
                position: absolute;
                content: '';
                background-color: $default-image-color;
                border-radius: 10px;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                z-index: 0;
              }
              &:after {
                position: absolute;
                content: '';
                background-color: #fff;
                width: 140px;
                height: 130px;
                transform: translate(-50%,-50%);
                left: 50%;
                top: 50%;
                z-index: 1;
              }

              &:hover {
                .fa-image {
                  color: darken($default-image-color, 3%);
                }
                &:before {
                  background-color: darken($default-image-color, 3%);
                }
              }
            }
          }
          .caption {
            padding-top: 5px;
            font-weight: normal;
            text-align: center;
            .name {
              color: #555;
              font-size: 13px;
              line-height: 1.1;
              font-weight: 500;
            }
            .address {
              color: #999;
              font-size: 11px;
              line-height: 1.1;
              margin-top: 3px;
            }
          }

          .add {
            position: absolute;
            top: 0;
            right: 8px;
            z-index: 3;
            &:before {
              position: absolute;
              content: '\2b';
              top: 2px;
              right: -8px;
              font: normal normal normal 18px/1 FontAwesome;
              text-rendering: auto;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              color: #666;
              z-index: 2;
              -webkit-transition: all 0.1s linear;
              transition: all 0.1s linear;
            }
            &:after {
              position: absolute;
              content: '';
              top: 0;
              right: -8px;
              background-color: rgba(#fff,.975);
              width: 28px;
              height: 28px;
              z-index: 1;
              //border-bottom-left-radius: 4px;
              transition: all 0.1s linear;
            }

            &:hover {
              &:before {
                color: #000;
              }
            }

            &.check, &.added {
              &:before {
                content: '\f00c';
              }
            }

            &.added {
              cursor: default;
              &:before {
                opacity: .5;
              }
              &:hover {
                &:before {
                  color: #666;
                }
              }
              &:after {
                border-bottom-left-radius: 4px;
              }
            }
          }

          .exclude {
            position: absolute;
            top: 0;
            right: 32px;
            z-index: 3;
            &:before {
              position: absolute;
              content: '\f05e';
              top: 3px;
              right: -8px;
              font: normal normal normal 18px/1 FontAwesome;
              text-rendering: auto;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              color: #666;
              z-index: 2;
              -webkit-transition: all 0.1s linear;
              transition: all 0.1s linear;
            }
            &:after {
              position: absolute;
              content: '';
              top: 0;
              right: -8px;
              background-color: rgba(#fff,.975);
              width: 28px;
              height: 28px;
              z-index: 1;
              border-bottom-left-radius: 4px;
              transition: all 0.1s linear;
            }

            &:hover {
              &:before {
                color: #000;
              }
            }
          }

          &.excluded {
            pointer-events: none;
            .photo {
              opacity: .25;
            }
            .add, .exclude {
              display: none;
            }
          }

          &:hover {
            cursor: pointer;
            .photo {
              img {
                filter: brightness(95%);
              }
            }
            .caption {
              .name {
                color: $brand-color;
              }
            }
          }

          /*.selected {
            border-radius: 10px;
            border-top-right-radius: 0;
            overflow: hidden;
            position: relative;



            .select {
              display: block;
              right: 10px;

              &:before {
                right: 0;
                z-index: 3;
              }

              &:after {
                right: 0;
                z-index: 2;
                background-color: #fff;
              }
            }
          }*/
        }
      }
    }

    .map + .infowindow {
      display: none;
    }
    .infowindow {
      padding: 8px;
      font-family: Montserrat,sans-serif;
      &:hover {
        cursor: pointer;
      }
      .photo {
        text-align: center;
        img {
          width: auto;
          max-height: 100px;
          border-radius: 10px;
          margin: 10px 0 3px 0;
        }
        .fa-image {
          width: 100%;
          max-height: 100px;
          height: auto;
          color: rgba(#000, .05);
        }
      }
      .caption {
        padding: 5px 5px 10px 5px;
        font-weight: normal;
        text-align: center;
        .name {
          color: #555;
          font-size: 14px;
          font-weight: 500;
        }
        .address {
          color: #999;
          font-size: 11px;
          line-height: 1.1;
        }
      }
    }
  }
  &.place-select-showing {
    z-index: 1000;
  }
  &.add-selected {
    .selected {
      margin: 25px 0;
      font-size: 12px;
      display: grid;
      grid-template-columns: 50% 50%;
      > div {
        border: 1px solid #ddd;
        padding: 5px;
      }
    }
  }
}

.pac-container {
  z-index: 2000;
}

.gm-style-iw-chr {
  display: none !important;
}
.gm-style-iw-d {
  overflow: visible !important;
}

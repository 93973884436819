@import "variables";

.detail {
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  padding: 20px;

  .form {
    max-width: none;
  }

  $label-color: #777;
  form {

    /*display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    height: 307px;
    column-gap: 25px;*/
    /*-webkit-columns: 2 200px;
    -moz-columns: 2 200px;
    columns: 2 200px;*/
    /*> div {
      max-width: 300px;
    }*/

/*    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;*/
    /*-webkit-column-width: 300px;
    -moz-column-width: 300px;
    column-width: 300px;

    -moz-column-fill: balance;
    column-fill: balance;
    margin-right: -80px;*/

    /*display: grid;
    grid-template-columns: 300px 300px 300px 300px; !*columns widths*!*/


    /*-moz-column-gap: 80px;
    -webkit-column-gap: 80px;
    column-gap: 80px;*/

    @media (max-width: 991px) {
      .row {
        .info {
          position: relative;
          left: 0;
          width: auto;
          /*left: 50%;
          transform: translateX(-50%);*/
        }
        .col + .info {
          margin-top: 25px;
          padding: 15px 0;
          position: relative;
          border-top: 1px solid #ddd;
        }
      }
    }

    .info {
      font-size: 11px;
      display: inline-grid;
      grid-template-columns: auto auto;
      grid-auto-rows: minmax(min-content, max-content);
      grid-column-gap: 10px;
      .label {
        color: #999;
        text-align: right;
        white-space: nowrap;
        margin: 0;
        /*padding: 0 15px 0 0;*/
      }
      .value {
        color: #000;
        white-space: nowrap;
        margin: 0;
        padding: 0;

        &.detail {
          border: 0;
          background-color: inherit;
          transition: color 0.2s;
          -webkit-transition: color 0.2s;
          .fa {
            padding-right: 6px;
            color: #999;
          }
          &:hover {
            color: $brand-color;
            cursor: pointer;
            .fa {
              color: $brand-color;
            }
          }
        }
      }

      /*.row {
        margin: 3px !important;
        label {
          color: $label-color;
          text-align: right;
          white-space: nowrap;
          margin: 0;
          padding: 0 15px 0 0;
        }
        .value {
          color: #000;
          white-space: nowrap;
          margin: 0;
          padding: 0;
        }
      }*/
    }

    > div {
      /*width: 300px;*/
    }
  }
}
.breadcrumb + .detail {
  margin-top: 20px;
}

@import "variables";

.index {
  height: 100%;
  .pipeline {
    display: none;
    background-color: transparent;
    padding: 0;
    margin-left: -5px;
    height: 100%;
    overflow-x: hidden;
    /*&.dragging {
      section {
        &.empty {
          &:after {
            opacity: .25;
          }
        }
      }
    }*/

    section {
      border: 1px solid #ccc;
      background-color: #f9f9f9;
      border-radius: 0;
      padding-bottom: 5px;
      max-height: 100%;

      &.empty {
        position: relative;
        padding-bottom: 25px;

        &:after {
          position: absolute;
          top: 40px;
          left: 15px;
          content: 'No campaigns';
          font-size: 11px;
          font-style: italic;
          color: #999;
        }

        > header {
          > span {
            margin-bottom: 5px;
          }
          .search {
            display: none;
          }
        }
      }

      > header {
        .title {
          color: #666;
          font-weight: 500;
          font-size: 13px;
          padding-bottom: 0;
          margin-bottom: 45px;
          position: relative;
          .filter {
            position: absolute;
            top: 0;
            right: -22px;
            font-family: agGridBalham;
            font-size: 16px;
            line-height: 16px;
            font-weight: 400;
            color: #bbb;
            transition: color 0.2s;
            -webkit-transition: color 0.2s;
            &:before {
              content: "\f114";
            }
            &:hover, &.open {
              cursor: pointer;
              color: #999;
            }
            &.active {
              cursor: pointer;
              color: $brand-color;
            }
          }
        }

        + div {
          max-height: calc(100vh - 225px);
          min-width: 100%;
          overflow-y: hidden;
          &:hover {
            overflow-y: scroll;
          }
        }

        .badge {
          background-color: #fff !important;
          color: #666;
          font-weight: 500;
          border: 1px solid #ddd;
          padding: 3px 5px;
          position: absolute;
          top: 9px;
          right: 10px;
        }
      }



      > header {
        + div {
          width: 100%;
        }
      }

      article {
        max-width: 100%;
        min-width: 100%;
        border: 1px solid #ddd;
        transition: border-color 0.1s linear, background-color 0.1s linear;

        &.hide {
          display: none;
        }

        > header {
          margin-bottom: 6px;
          padding-bottom: 2px;
          > span {
            width: 100%;
          }
        }

        .title {
          font-weight: normal;
          font-size: 12px;
          color: #000;
          transition: color 0.1s linear;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .description {
          > div {
            font-size: 11px;
            color: #666;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            span {
              color: #999;
              padding-right: 8px;
            }
          }
        }

        button {
          display: none;
        }

        &:hover {
          border-color: #c2c2c2;
          background-color: #fcfcfc;

          .title {
            color: $brand-color;
          }
        }
      }
    }

    .smooth-dnd-container {
      min-width: 100%;
    }
  }
  &.pipeline-showing {
    > .header {
      .icon {
        &.fa-plus, &.fa-download {
          display: none;
        }
      }
      .count {
        display: none;
      }
    }

    > .grid {
      display: none;
    }

    .pipeline {
      display: block;
    }

    > .detail {
      + .pipeline {
        display: none;
      }
      + .spinner {
        display: none;
      }
    }
  }

  &.contact {
    > .pipeline {
      section {
        &.empty {
          &:after {
            content: 'No contacts';
          }
        }
      }
    }
  }
}

.popover.pipeline.lane-filter {
  position: relative;
  .form-control {
    padding: 5px 20px 5px 10px;
    font-size: 11px;
    color: #333;
    border: 0;
    background-color: transparent;

    &::-webkit-input-placeholder {
      color: #999;
    }

    &:focus {
      box-shadow: none;
      border-color: #ccc;
    }

    &.active {
      + .clear {
        display: block;
      }
    }
  }
  .clear {
    display: none;
    position: absolute;
    top: 6.5px;
    right: 6px;
    color: #ccc;
    font-size: 12px;
    transition: color 0.2s;
    -webkit-transition: color 0.2s;
    &:hover {
      cursor: pointer;
      color: #999;
    }
  }
}

@import 'mdb-react-ui-kit/dist/scss/free/_variables';
@import 'variables';

.form {
  max-width: 300px;
  width: 100%;
  $label-color: #777;

  + .form {
    margin-top: 25px;
  }

  form {
    .row {
      + .row {
        margin-top: 15px;
      }
      > div {
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
      .col {
        &.full-height {
          > div {
            height: 100%;
            .form-outline {
              height: 100%;
              .form-control {
                height: 100%;
              }
            }

            &.tag {
              .react-tags-wrapper {
                top: 2px;
              }
            }

            &.image {
              .form-outline {
                ~ div {
                  .fa-image {
                    margin-top: -15px;
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }

        .textarea {
          height: 65px;
          box-sizing: border-box;
          position: relative;
          .form-outline {
            height: 100%;

            textarea {
              height: 100%;
              line-height: 1.5;
              padding-top: 7px;
            }
          }

          .form-outline {
            padding-top: 12px;
            background-color:#fff;
            textarea {
              padding-top: 0;
            }
          }
        }

        .tag {
          position: relative;
          box-sizing: border-box;
          > div {
            height: 100%;
            .form-outline {
              height: 100%;
              .form-control {
                height: 100%;
              }
            }
          }
          .react-tags-wrapper {
            position: absolute;
            top: 17px;
            left: 2px;
            width: calc(100% - 4px);
            /*height: calc(100% - 4px);*/
            border-radius: 3.2px;
            padding: 5px 6px 3px 6px;
            overflow-y: auto;
            > div {
              line-height: 1;
              .tag-wrapper {
                background-color: #f9f9f9;
                font-size: 11px;
                padding: 2px 4px;
                white-space: nowrap;
                line-height: 1.2;
                margin-right: 4px;
                + .tag-wrapper {
                  /*margin-right: 4px;*/
                }
                button {
                  background-color: transparent;
                  outline: 0;
                  border: 0;
                  font-size: 19px;
                  position: relative;
                  top: 3px;
                  padding: 0 0 0 6px;
                  color: #999;

                  &:hover {
                    color: #333;
                  }
                }
              }
              &:last-child {
                width: 100%;
                margin-top: 3px;
                > input {
                  width: 100%;
                  border: 0;
                  background-color: transparent;
                  font-size: 11px;
                  padding-left: 4px;
                  &:focus, &:focus-visible {
                    outline: 0;
                  }
                }
              }
            }

            .ReactTags__suggestions {
              position: absolute;
              top:60px;
              background-color: #fff;
              box-shadow: 0 3px 6px 3px rgb(221 221 221 / 50%);
              border: 1px solid #aaa;
              padding: 0;
              border-radius: 4px;
            }
            .ReactTags__suggestions ul {
              list-style-type: none !important;
              box-shadow: none;
              margin: 0 !important;
              padding: 0 !important;
            }
            .ReactTags__suggestions li {
              border-bottom: 0;
              margin: 0;
              color: #666;
              font-size: 11px;
              line-height: 2;
              padding: 0 5px;
              white-space: nowrap;
              &:first-child {
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
              }
              &:last-child {
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
              }
            }
            .ReactTags__suggestions li mark {
              text-decoration: underline;
              background: none;
              font-weight: 600;
            }
            .ReactTags__suggestions ul li.ReactTags__activeSuggestion {
              background-color: $hover-bg;
              color: #000;
              cursor: pointer;
            }

            .ReactTags__remove {
              border: none;
              cursor: pointer;
              background: none;
              color: white;
            }
          }

        }

        .checkbox {
          .btn {
            width: auto;
            color: #777;
            background-color: #fff;
            border-width: 1px !important;
            border-color: #bdbdbd;
            font-size: 11px;
            line-height: 1.8;
            padding: 3px 10px;
            white-space: nowrap;
            text-align: left;

            .fa {
              padding-left: 5px;
              color: #999;
              transition: color 0.2s linear;
              font-size: 10px;
            }

            &:hover, &.active {
              cursor: pointer;
              /*box-shadow: inset 0 2px 3px rgba(#000, .05) !important;*/
              color: #000;
              .fa {
                color: #777;
              }
            }

            &.active {
              position: relative;
              &:before {
                content: '';
                background-color: #f9f9f9;
                border-radius: 100%;
                width: 12px;
                height: 12px;
                position: absolute;
                top: -3px;
                right: -3px;
                z-index: 1;
              }
              &:after {
                border: 0;
                position: absolute;
                top: -4px;
                right: -4px;
                content: "\f00c";
                font: normal normal normal 12px/1 FontAwesome;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                z-index: 2;
              }

              .fa {
                padding-right: 10px;
              }
            }
          }

          &.many {
            position: relative;
            .form-outline {
              height: 100%;
              background-color: #fff;
              padding: 11px 10px 6px 11px;

              /*width: auto;
              padding-top: 18px;
              padding-bottom: 8px;
              line-height: 1;
              background-color: #fff;
              border: 1px solid #bdbdbd;
              border-radius: 4px;*/

              /*~ .form-label {
                padding-left: 5px;
                margin-left: -4px;
                padding-right: 5px;
                background-color: #f9f9f9;
              }*/

              /*.row {
                .col {
                  > div + > div {
                    padding-top: 15px;
                  }
                }
              }*/

              > .form-control {
                display: none;
              }

              > .row {
                > .col {
                  padding-top: 0;
                  > div {
                    margin-top: 0 !important;
                  }
                }
                .form-check {
                  position: relative;
                  margin: 0 0 6px 0;
                  padding: 0;
                  min-height: initial;
                  white-space: nowrap;
                  line-height: 1;
                  .form-check-input[type=checkbox] {
                    float: none;
                    margin: 0;
                    background-image: none;
                    top: 1.5px;
                    width: 15px;
                    height: 15px;
                    border-color: #bdbdbd;
                    background-color: #fff;
                    &:focus {
                      box-shadow: none;
                      &:before, &:after {
                        display: none;
                      }
                    }
                    &:active {
                      filter: brightness(95%);
                      border-color: #999;
                    }
                  }
                  .form-check-label {
                    color: #777;
                    line-height: 15px;
                    padding-left: 5px;
                    margin-bottom: 0 !important;
                    font-size: 11px;
                    line-height: 1;
                  }

                  &.checked, &:hover {
                    cursor: pointer;
                    .form-check-input[type=checkbox] {
                      border-color: #999;
                    }
                    .form-check-label {
                      color: #000;
                    }
                  }
                  &.checked {
                    .form-check-input[type=checkbox] {
                      &:after {
                        display: block;
                        border: 0;
                        position: absolute;
                        width: auto;
                        height: auto;
                        top: 50%;
                        left: 50%;
                        content: "\f00c";
                        font: normal normal normal 10px/1 FontAwesome;
                        text-rendering: auto;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        transform: translate(-50%,-50%) rotate(0);
                        margin: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        /*> div {
          + .checkbox.many {
            margin-top: 30px !important;
          }
        }*/

        .autocomplete {
          .list {
            margin-top: -3px;
            position: absolute !important;
            z-index: 999;
            background-color: #fff;
            box-shadow: 0 3px 6px 3px rgba(#ddd, .25);
            border: 1px solid #aaa;
            padding: 10px 0;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            &:focus, &:focus-visible {
              outline: 0;
            }
            .item {
              font-size: 11px;
              line-height: 1.5;
              padding: 0 10px;
              white-space: nowrap;
              &:hover, &.active {
                background-color: $hover-bg;
                color: #000;
                cursor: pointer;
              }
              &.empty {
                font-style: italic;
                color: #888;
                &:hover, &:focus {
                  background-color: transparent;
                  cursor: default;
                }
              }

              mark {
                padding: 0;
              }
            }
          }
          .dropdown-item {
            font-size: 11px;
            &:hover, &.active {
              background-color: $hover-bg;
              color: #000;
              cursor: pointer;
            }
            &:hover {
              color: $brand-color;
            }
            &.empty {
              font-style: italic;
              color: #888;
              &:hover, &:focus {
                background-color: transparent;
                cursor: default;
              }
            }
          }

          .form-control {
            &.nofilter {
              cursor: pointer;
              caret-color: transparent;
              &.empty {
                color: transparent;
                ~ .form-label {
                  transform: none;
                }
                ~ .form-notch .form-notch-middle {
                  border-top: 1px solid #bdbdbd;
                }
              }
            }
          }
        }

        .image {
          position: relative;
          .form-outline {
            border: 0;
            .form-control {
              /*margin-top: 15px;
              width: auto;
              display: inline-block;
              border: 1px solid #999;
              height: 100%;
              padding: 5px;
              line-height: 1;*/

              /*~ .form-label {
                left: 0;
              }*/
              background-color: transparent;

              ~ .form-label {
                left: 0;
              }
            }
            .form-notch {
              display: none;
            }

            ~ div {
              width: auto;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              padding-top: 12px;
              padding-right: 12px;
              box-sizing: content-box;

              img, .fa-image {
                width: 100%;
                max-height: 100%;
                height: auto;
                &:focus, &:active {
                  outline: 0;
                }
              }
              .fa-image {
                color: rgba(#000, .05);
                vertical-align: top;
                margin-top: -25px;
                margin-bottom: -12px;
                transition: color 0.2s linear;
                + .fa-plus {
                  position: absolute;
                  z-index: 999;
                  top: 45px;
                  right: 45px;
                  width: 15px;
                  height: 15px;
                  color: rgba(#000, .35);
                  transition: all 0.2s linear;
                  @media (max-width: 991px) {
                    top: 40px;
                    right: 30px;
                  }
                }
              }

              .spinner {
                top: 12px !important;
                left: auto !important;
                right: 10px;
                width: 15px;
                height: 15px;
                z-index: 2;

                + div {
                  position: absolute;
                  top: 9px;
                  right: 7px;
                  background-color: #f9f9f9;
                  border-radius: 50%;
                  width: 22px;
                  height: 22px;
                  z-index: 1;
                }
              }

              .edit, .delete {
                display: none;
                position: absolute;
                top: -8px;

                @media (hover: none) {
                  display: block;
                }

                &.edit {
                  right: 34px;
                  &:before {
                    content: '\f040';
                  }
                  &:after {
                    border-bottom-left-radius: 4px;
                  }
                }
                &.delete {
                  right: 12px;
                  &:before {
                    content: '\f2ed';
                  }
                }
                &:before {
                  position: absolute;
                  top: 18px;
                  right: -7px;
                  font: normal normal normal 13px/1 FontAwesome;
                  text-rendering: auto;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                  color: #999;
                  z-index: 2;
                  transition: color 0.2s;
                  -webkit-transition: color 0.2s;
                }
                &:after {
                  position: absolute;
                  top: 15px;
                  right: -8px;
                  content: '';
                  background-color: rgba(#f9f9f9,.975);
                  // border-radius: 50%;
                  width: 22px;
                  height: 22px;
                  z-index: 1;
                }
                &:hover {
                  pointer-events: inherit;
                  cursor: pointer;
                  &:before {
                    color: $brand-color;
                  }
                }
              }

              .download {
                position: absolute;
                top: -10px;
                right: 0;
                color: #999;
                z-index: 2;
                transition: color 0.2s;
                -webkit-transition: color 0.2s;
                font-size: 13px;
                &:hover {
                  pointer-events: inherit;
                  cursor: pointer;
                  color: $brand-color;
                }
              }

              &:hover {
                cursor: pointer;
                img {
                  filter: brightness(95%);
                }
                .fa-image {
                  color: rgba(#000, .075);
                  + .fa-plus {
                    color: $brand-color;
                  }
                }
                .edit, .delete {
                  display: block;
                }
                .spinner ~ .edit, .spinner ~ .delete {
                  display: none;
                }
              }

              /*&:after {
                display: none;
                border: 0;
                position: absolute;
                top: -17px;
                right: 0;
                content: "\f040";
                font: normal normal normal 11px/1 FontAwesome;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                color: #bbb;
              }*/
            }

            @media (max-width: 991px) {
              .form-label {
                padding-top: 15px;
              }
              ~ div {
                position: relative;
                display: inline-block;
                margin-top: -15px;
                img, .fa-image {
                  max-height: 200px;
                  width: auto;
                }
                .fa-image {
                  width: 200px;
                }
              }
            }
          }

          &.nolabel {
            margin-top: -12px;
          }
          &.readonly {
            .form-outline {
              ~ div {
                padding-right: 0;
                &:hover {
                  img {
                    filter: none;
                  }
                  .fa-image {
                    &:hover {
                      cursor: default;
                      color: rgba(#000, .05);
                    }
                  }
                }
              }
            }
          }
        }

        .date {
          position: relative;
          .react-datepicker-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            .react-datepicker__input-container {
              padding: 1px;
              box-sizing: border-box;
              input {
                border: 0;
                font-size: 11px;
                line-height: 1.8;
                padding: 5px 10px 3px 11px;
                width: 100%;
                height: 100%;
                background-color: transparent;
                &:focus-visible {
                  outline: 0;
                }
              }
            }
          }
          .react-datepicker {
            font-family: Montserrat,sans-serif;
            .react-datepicker__navigation-icon {
             top: 6px;
            }
            .react-datepicker__current-month {
              color: #333;
              font-weight: 500;
            }

            .react-datepicker__year-dropdown-container--scroll {
              position: absolute;
              top: 10px;
              left: 50%;
              transform: translateX(-50%);
              width: calc(100% - 100px);
              height: 20px;
              .react-datepicker__year-read-view {
                width: 100%;
                height: 100%;
                > * {
                  display: none;
                }
              }
            }
            .react-datepicker__year-dropdown {
              padding: 5px 0;
              background-color: #f4f4f4;
              .react-datepicker__year-option {
                color: #666;
                &:hover {
                  color: #333;
                  background-color: #e8e8e8;
                }
                &.react-datepicker__year-option--selected_year {
                  color: #333;
                  font-weight: 500;
                  background-color: #dedede;
                  > span {
                    left: 6px;
                  }
                }
              }
              .react-datepicker__navigation--years-previous {
                display: none;
              }
            }

            .react-datepicker__day-names {
              padding-top: 4px;
            }
          }
        }

        .inline-add, .linked {
          position: absolute;
          top: 9px;
          right: 12px;
          font-size: 11px;
          color: #bbb;
          transition: color 0.2s;
          -webkit-transition: color 0.2s;
          &:hover {
            cursor: pointer;
            color: $brand-color;
          }
        }
        .linked {
          top: -5px;
          right: -5px;
          &:before {
            padding: 0 3px 3px 3px;
            box-sizing: content-box;
            background: linear-gradient(to top, rgba(#fff,0) 0%,rgba(#fff,1) 20%,rgba(#fff,1) 100%);
          }
        }

        .inline-button {
          position: absolute;
          top: 1px;
          right: 0;
          font-size: 11px;
          color: #777;
          border-left-width: 1px !important;
          border-color: #bdbdbd;
          border-radius: 0;
          padding: 5px 8px;
          transition: color 0.2s;
          -webkit-transition: color 0.2s;
          &:hover, &:active, &:focus {
            background-color: transparent;
            cursor: pointer;
            color: #000;
          }
        }

        .text, .textarea {
          .icons {
            position: absolute;
            top: -13px;
            right: 1px;
            &:after {
              position: absolute;
              z-index: 1;
              content: '';
              padding: 0 3px 0 3px;
              box-sizing: content-box;
              background: linear-gradient(to top, rgba(#fff,0) 0%,rgba(#fff,1) 20%,rgba(#fff,1) 100%);
              //background-color: #fff;
              width: 100%;
              height: 12px;
              top: 12px;
              right: -1px;
            }

            .icon {
              color: #999;
              font-size: 13px;
              transition: color 0.2s;
              -webkit-transition: color 0.2s;
              position: relative;
              z-index: 2;
              &:hover {
                cursor: pointer;
                color: $brand-color;
              }
              + .icon {
                margin-left: 10px;
              }

              &:last-child.fullscreen {
                color: #bbb;
                font-size: 12px;
              }

              &.disabled {
                opacity: .5;
              }
            }
          }
        }



        > div {
          + div {
            margin-top: 15px !important;
            &[style^=height] {
              padding-top: 15px;
              margin-top: 0 !important;
            }
          }
        }

        .row {
          margin: 0;

          > .col {
            &:not(:first-child) {
              padding-left: calc(var(--bs-gutter-x) * 0.25);
            }
            &:not(:last-child) {
              padding-right: calc(var(--bs-gutter-x) * 0.25);
            }
          }
        }
      }
    }

    @media (max-width: 991px) {
      .row {
        display: block;
        > div {
          padding: 0;
        }
        > .col {
          padding-top: 15px;
          &:first-child {
            padding-top: 0;
          }

          &.col-md-2 {
            width: 100%;
          }

          > .tag, .textarea:not(.has-height) {
            height: 65px !important;
          }

          .row {
            > .col {
              &:not(:first-child) {
                padding-left: 0;
              }
              &:not(:last-child) {
                padding-right: 0;
              }
            }
          }
        }
      }
    }

    .form-outline {
      .form-control {
        background-color: #fff;
        color: #000;
        &.form-control-sm {
          font-size: 11px;
          line-height: 1.8;
          padding: 5px 10px 3px 11px;
          /*margin-bottom: 10px;*/
          ~ .form-label {
            font-size: 12px;
          }
        }

        &:valid, &:invalid {
          background-image: none;
        }

        &:valid:not(.invalid) {
          $valid-color: $gray-400;
          ~ .form-notch {
            .form-notch-leading {
              border-color: $valid-color;
            }
            .form-notch-middle {
              border-color: $valid-color;
            }
            .form-notch-trailing {
              border-color: $valid-color;
            }
          }
          ~ .form-label {
            color: $label-color;
          }
        }

        &:focus, &.focused {
          $focus-color: #666;
          ~ .form-notch {
            .form-notch-leading {
              border-top: 1px solid $focus-color;
              border-bottom: 1px solid $focus-color;
              border-left: 1px solid $focus-color;
            }
            .form-notch-middle {
              border-bottom: 1px solid;
              border-color: $focus-color;
              border-top: none;
              border-right: none;
              border-left: none;
              transition: all 0.2s linear;
            }
            .form-notch-trailing {
              border-color: currentcolor currentcolor currentcolor $focus-color;
              border-bottom: 1px solid $focus-color;
              border-right: 1px solid $focus-color;
              border-top: 1px solid $focus-color;
            }
          }
          ~ .form-label {
            color: $label-color;
            transform: translateY(-0.85rem) translateY(0.1rem) scale(0.8);
          }
        }

        &.invalid {
          ~ .form-notch {
            .form-notch-leading {
              border-top: 1px solid $form-feedback-invalid-color;
              border-bottom: 1px solid $form-feedback-invalid-color;
              border-left: 1px solid $form-feedback-invalid-color;
            }
            .form-notch-middle {
              border-bottom: 1px solid;
              border-color: $form-feedback-invalid-color;
            }
            .form-notch-trailing {
              border-color: currentcolor currentcolor currentcolor $form-feedback-invalid-color;
              border-bottom: 1px solid $form-feedback-invalid-color;
              border-right: 1px solid $form-feedback-invalid-color;
              border-top: 1px solid $form-feedback-invalid-color;
            }
          }
          ~ .form-label {
            color: $label-color;
          }

          ~ .invalid-feedback {
            display: block;
          }

          ~ .linked {
            display: none;
          }
        }

        &:-webkit-autofill {
          ~ .form-label {
            transform: translateY(-0.85rem) translateY(0.1rem) scale(0.8);
          }
          ~ .form-notch .form-notch-middle {
            border-top: none;
          }
        }

        &[type=number] {
          padding-right: 3px;
        }

        &[type=date] {
          display: block !important;
          padding-right: 6px;
          &.empty {
            &:not(:focus) {
              color: transparent;

              ~ .form-label {
                transform: none;
              }
              ~ .form-notch .form-notch-middle {
                border-top: 1px solid #bdbdbd;
              }
            }
          }
        }

        &[type=email] {
          &.empty {
            ~ .linked {
              display: none;
            }
          }
        }

        &[readonly] {
          pointer-events: none;
        }
      }

      .invalid-feedback {
        position: absolute;
        top: -12px;
        right: 8px;
        font-size: 10px;
        font-style: italic;
        background-color: #fff;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        padding: 0 5px;
        box-sizing: content-box;
        z-index: 2;
        width: auto;
      }
    }

    &.was-validated {
      .form-outline {
        .form-control {
          margin-bottom: 0;
          &:valid:not(.invalid) {
            $valid-color: $gray-400;

            ~ .form-notch {
              .form-notch-leading {
                border-color: $valid-color;
              }

              .form-notch-middle {
                border-color: $valid-color;
              }

              .form-notch-trailing {
                border-color: $valid-color;
              }
            }

            ~ .form-label {
              color: $label-color;
            }
          }
          &:invalid {
            ~ .form-notch {
              .form-notch-leading {
                border-color: $form-feedback-invalid-color;
              }

              .form-notch-middle {
                border-color: $form-feedback-invalid-color;
              }

              .form-notch-trailing {
                border-color: $form-feedback-invalid-color;
              }
            }
            ~ .form-label {
              color: $label-color;
            }
          }
        }
      }
    }

    .readonly {
      white-space: nowrap;
      /*> div {
        display:inline-block;
      }*/
      .label {
        color: $label-color;
      }
      .value {
        color: $gray-900;
      }
    }

    .bold {
      .form-control {
        font-weight: bold;
        &:not(.empty) {
          ~ .form-label {
            font-weight: bold;
          }
        }
      }
    }

    .submit {
      margin-top: 15px;
      .btn {
        position: relative;
        .spinner {
          top: 7px;
          left: calc(50% + 30px);
          border-color: #fff;
          border-right-color: transparent;
          width: 20px;
          height: 20px;
        }
      }
      .alert {
        display: inline-block;
        margin-left: 20px;
        margin-bottom: 0;
        font-size: 11px;
        padding: 7px 14px;
        font-style: italic;
      }
    }
  }

  &.md {
    form {
      .form-outline {
        .form-control {
          font-size: 13px;
          ~ .form-label {
            font-size: 13px;
            padding-top: 6px;
          }

          &:focus, &.active {
            ~ .form-label {
              padding-top: 6px;
            }
          }
        }
      }
    }
  }

  + .submit {
    margin-top: 15px;
    + .tabs {
      margin-top: 15px;
    }
  }
}

.modal {
  .form form .row .col .autocomplete .list {
    box-shadow: none;
  }
}

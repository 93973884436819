@import "variables";

.tabs {
  &.nav-pills {
    > .nav-item {
      > .nav-link {
        background-color: transparent;
        box-shadow: none;
        padding: 0;
        font-size: 14px;
        text-transform: none;
        color: #aaa;
        font-weight: normal;
        &:hover {
          color: $brand-color;
        }
        &.active {
          color: #666;
          font-weight: 500;
          pointer-events: none;
          &:hover {
            cursor: default;
          }
        }

        @include media-breakpoint-down(sm) {
          font-size: 13px;
        }
      }
      + .nav-item {
        > .nav-link {
          margin-left: 25px;
        }
      }
    }
    + .tab-content {
      margin-top: 14px;
      position: relative;

      .tab-pane.note {
        border: 0;
      }

      .grid {
        + div {
          padding-top: 30px;
        }
      }

      .map {
        /*margin-top: 10px;*/
        border: 1px solid #ccc;
      }

      .tab-pane {
        > div {
          > .row {
            > .col {
              &:first-child {
                padding: 0;
              }
              &:last-child {
                padding: 0;
              }
              > .form {
                margin-top: 5px;
              }
            }
          }
        }
        .nav-pills {
          border-top: 1px solid #e5e5e5;
          margin-top: -4px;
          padding-top: 4px;
          > .nav-item {
            > .nav-link {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}

.form + .tabs {
  margin-top: 25px;
}
.detail > .tabs:first-child {
  margin-top: -5px;
}

@import "variables";

.grid {
  height: 100%;
  font-family: Montserrat, sans-serif;

  .ag-root-wrapper {
    border-color: #ccc;
    overflow: visible;
  }

  &.loading {
    .ag-root-wrapper {
      border-color: rgba(#ccc, .5);
    }
  }

  .ag-header-cell {
    .ag-header-cell-text {
      font-size: 11px;
      font-weight: normal;
      color: #333;
    }
    &.bold {
      .ag-header-cell-text {
        font-weight: 500;
        color: #000;
      }
    }

    .ag-sort-icon {
      display: none;
    }
    .ag-header-cell-sorted-asc {
      .ag-sort-ascending-icon {
        display: block;
      }
    }
    .ag-header-cell-sorted-desc {
      .ag-sort-descending-icon {
        display: block;
      }
    }

    .group-toggle {
      position: relative;
      top: -6px;
      padding: 0 6px 0 8px;
      &:before {
        display: block;
        position: absolute;
        width: 10px;
        height: 10px;
        color: #999;
        transition: color 0.1s linear;
        font: normal normal normal 12px/1 FontAwesome;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      &:hover {
        &:before {
          color: #333;
        }
      }
      &.group-hidden {
        &:before {
          content: '\f105';
        }
      }
      &:not(.group-hidden) {
        &:before {
          content: '\f104';
        }
      }
    }
  }

  .ag-popup {
    height: 0;
  }
  .ag-menu {
    max-height: none;
  }

  .ag-row {
    //font-size: 12px;
    .ag-cell {
      font-size: 11px;

      @include media-breakpoint-down(sm) {
        line-height: 35px;
      }

      //line-height: min(var(--ag-row-height, 25px), 25px);
      //&.ag-cell-range
      &.ag-cell-focus {
        border-color: transparent !important;
      }

      &.linked {
        .fa {
          //margin-left: -11px;
          padding-right: 8px;
          color: #bbb;
          &:hover {
            cursor: pointer;
            color: $brand-color;
          }
        }
      }

      &.image {
        .fa {
          color: #bbb;
          &:hover {
            cursor: pointer;
            color: $brand-color;
          }
        }
      }

      &.icons {
        text-align: center;
        .fa {
          color: #999;
          font-size: 12px;
          &:hover {
            cursor: pointer;
            color: $brand-color;
          }
        }
      }
      &.bold {
        font-weight: 500;
      }
      &.italic {
        font-style: italic;
      }
      &.light {
        color: #999;
      }

      &.editable {
        &:after {
          position: absolute;
          top: 6px;
          right: 4px;
          content: '\f303';
          color: #c2c2c2;
          font: normal normal normal 11px/1 FontAwesome;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          z-index: 2;
        }
        &.ag-cell-inline-editing {
          input {
            padding-right: 20px;
          }
        }
        &:not(.ag-cell-inline-editing) {
          padding-right: 20px;
          &:hover {
            cursor: pointer;
            &:after {
              color: $brand-color;
            }
          }
        }
      }

      &.group {
        .group-toggle {
          display: inline-block;
          margin-left: -3px;
          padding-right: 6px;
          font-size: 13px;
          width: 16px;
          color: #bbb;
          text-align: center;
          &:hover {
            cursor: pointer;
            color: $brand-color;
          }
        }
      }
      &.group-child {
        padding-left: 26px;
      }
    }
    &.ag-row-last {
      border-bottom-color: transparent;
    }
    &.ag-row-selected {
      background-color: $hover-bg !important;
      &:before {
        background-color: $hover-bg !important;
      }
      .ag-cell {
        background-color: $hover-bg !important;
      }
    }
  }

  .ag-row-hover {
    background-color: rgba($hover-bg, .85);
    .ag-cell {
      //&.linked:not(.empty) {
      //  color: $brand-color;
      //  &:hover {
      //    cursor: pointer;
      //    &:after {
      //      position: absolute;
      //      top: 6px;
      //      right: 4px;
      //      content: "\f08e";
      //      color: #999;
      //      font: normal normal normal 10px/1 FontAwesome;
      //      text-rendering: auto;
      //      -webkit-font-smoothing: antialiased;
      //      -moz-osx-font-smoothing: grayscale;
      //      z-index: 2;
      //    }
      //  }
      //}
    }
  }

  &.rowclick {
    .ag-row-hover {
      cursor: pointer;
      .ag-cell {
        color: $brand-color;
      }
    }
  }

  &:not(.rowclick) {
    .ag-row-hover {
      cursor: default;
    }
  }

  .ag-menu {
    background: lighten(#f5f7f7, 1%);

    .ag-filter {
      .ag-picker-field-wrapper, .ag-input-field-input {
        &:focus, &:active {
          outline: 0;
          box-shadow: none;
        }
      }
      .ag-input-field-input {
        padding: 5px 10px 3px 11px;
      }
      .ag-filter-apply-panel {
        [ref=clearFilterButton] {
          color: #666;
          background-color: transparent;
          border: 0;

          &:focus, &:active {
            outline: 0;
            box-shadow: none;
          }

          &:hover {
            color: #000;
          }
        }
      }
    }

    .ag-filter {
      &:not(.is-filtered) {
        .ag-filter-apply-panel {
          display: none;
        }
      }
    }
  }

  .ag-checkbox-input-wrapper {
    input {
      &:hover {
        cursor: pointer;
      }
    }
    &:hover {
      &:after {
        color: $brand-color !important;
      }
    }
    &.ag-checked {
      &:after {
        color: $brand-color !important;
      }
    }
    &:focus-within, &:active {
      box-shadow: 0 0 2px 1px rgba(#666, .15);
    }
  }

  &.empty {
    &.filtered {
      margin-bottom: 30px;
      .alert {
        margin-top: -2px;
      }
      + .row {
        margin-top: 25px;
      }
    }
    &:not(.filtered) {
      > * {
        &:first-child {
          display: none;
        }
      }
    }
  }
}

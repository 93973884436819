.index.booked-business {
  position: relative;
  > .filter {
    position: absolute;
    top: 0;
    right: 30px;
    width: 150px;
    display: flex;

    .startDate {
      color: #666;
      font-size: 11px;
      line-height: 30px;
      white-space: nowrap;
      span {
        padding: 0 6px;
      }
    }
  }
}

@import "variables";

.google-doc {
  /*border: 1px solid #ccc;*/
  position: relative;
  /*&:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #f9f9f9;
  }*/

  > .header {
    /*margin-right: 120px;*/
  }

  > .file.dropdown {
    position: absolute;
    z-index: 1;
    transform: translateY(-100%);
    top: -3px;
    /*right: -10px;*/
    right: 50px;

    .dropdown-toggle {
      font-size: 12px;
      color: #999;
      background-color: transparent;
      &:hover {
        color: $brand-color;
      }
    }
    .dropdown-menu {
      font-size: 12px;
      max-height: 400px;
      overflow-y: auto;
      .dropdown-header {
        font-size: 12px;
        color: #888;
      }
      .dropdown-item {
        font-size: 11px;
        color: #777;
        &:hover {
          color: $brand-color;
        }
        &.active {
          color: #000;
          background-color: #f1f1f1;
        }
      }
    }
  }

  iframe {
    margin-top: 1px;
    border: 1px solid #ccc;
    width: 100%;
    height: calc(100vh - 100px);
  }
}

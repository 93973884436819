@import "variables";

.map {
  .autocomplete {
    position: absolute;
    top: 7px;
    left: 50%;
    box-sizing: border-box;
    border: 1px solid transparent;
    width: 250px;
    height: 30px;
    transform: translateX(-50%);
    padding: 0 12px;
    border-radius: 3px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    font-size: 12px;
    outline: none;
    text-overflow: ellipsis;
  }

  .gm-style {
    .gm-style-mtc {
      button {
        font-size: 12px !important;
        font-weight: normal !important;
        + ul {
          li {
            padding-left: 12px !important;
            font-size: 12px !important;
            font-weight: normal !important;
            &[role=menuitemcheckbox] {
              > span {
                padding-right: 5px;
              }
            }
          }
        }
      }
    }
  }
}
/*.map {
  .add {
    !*position: absolute;
    z-index: 1;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);*!
    transform: translateX(-50%);
    background-color: rgba(#fff, .9);
    padding: 8px 12px;
    box-shadow: 0 0 2px 2px rgba(#666, .05);
    border: 1px solid #bbb;
    color: #333;
    font-size: 20px;
    font-weight: 500;
    transition: color 0.2s linear;
    &:hover {
      cursor: pointer;
      color: $brand-color;
    }
  }

  &.panorama {
    .add {
      margin-top: 10px;
      background-color: rgba(#000, .65);
      color: #ccc;
      !*border-color: rgba(#fff, .4);*!
      border: 0;
      box-shadow: none;
      &:hover {
        color: #fff;
      }
    }
  }
}*/

.pano {
  width: 100%;
  height: 100%;
}


@import "variables";

.tab-pane {
  &.photo {
    > .header {
      position: absolute;
      top: 12px;
      right: 0;
    }
    > .swiper {
      margin-top: -25px;
      .swiper-slide {
        @include media-breakpoint-up(lg) {
          height: 450px;
          .col {
            height: 412px;
          }
        }
        > * {
          &:first-child {
            padding-top: 25px;
          }
        }
      }

      .swiper-button-prev, .swiper-button-next {
        color: #999;
        top: auto;
        bottom: -25px;
        &:after {
          display: none;
        }
        &:before {
          position: absolute;
          top: 0;
          content: '\f054';
          font: normal normal normal 17px/1 FontAwesome;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          color: #999;
          z-index: 2;
          transition: color 0.2s;
          -webkit-transition: color 0.2s;
        }

        &:hover {
          &:before {
            color: $brand-color;
          }
        }
      }
      .swiper-button-prev {
        left: -8px;
        &:before {
          content: '\f053';
        }
      }
      .swiper-button-next {
        right: -8px;
        &:before {
          content: '\f054';
        }
      }

      .swiper-pagination {
        padding-top: 12px;
        text-align: center;
        font-size: 12px;
        color: #666;
      }

      .spinner {
        top: 50%;
      }
      .loading {
        position: relative;
        box-sizing: content-box;
        svg {
          color: #eee;
          width: 100%;
          height: 400px;
          position: absolute;
          top: 25px;
          left: 0;
          &.fa-image {
            z-index: 2;
            &:hover {
              cursor: grab;
            }
          }
          &.fa-square {
            z-index: 1;
            color: #fff;
            background-color: #eee;
          }
        }
      }
      .form {
        .image {
          height: 100%;
          .form-outline {
            ~ div {
              width: 100%;
              img {
                object-fit: cover;
                height: 100% !important;
                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }
        .info {
          .value {
            max-width: 150px;
            white-space: normal;
          }
        }
      }
    }

    //.col {
    //  .tile {
    //    height: 300px;
    //    margin-bottom: 50px;
    //    .loading {
    //      position: relative;
    //      box-sizing: content-box;
    //      width: 100%;
    //      height: 100%;
    //      svg {
    //        color: #eee;
    //        width: 100%;
    //        height: 100%;
    //        position: absolute;
    //        top: 0;
    //        left: 0;
    //        &.fa-image {
    //          z-index: 2;
    //          &:hover {
    //            cursor: grab;
    //          }
    //        }
    //        &.fa-square {
    //          z-index: 1;
    //          color: #fff;
    //          background-color: #eee;
    //        }
    //      }
    //    }
    //
    //    .caption {
    //      font-size: 12px;
    //      color: #666;
    //      display: flex;
    //      > div {
    //
    //      }
    //    }
    //  }
    //}
  }
}

.view.login {
  display: flex;
  justify-content: center;

  form {
    .submit {
      text-align: center;
      .btn {
        width: 100%;
      }
      .alert {
        margin-top: 20px;
        margin-left: 0;
        display: block;
        font-size: 12px;
      }
    }
  }
}


.index.route-distribution {
  $filter-height: 50px;
  height: calc(100% - $filter-height);
  > .filter {
    display: flex;
    justify-content: center;
    height: $filter-height - 20px;
    position: relative;
    margin-top: 20px;

    + .row {
      position: relative;
      border-top: 1px solid #efefef;
      margin-top: 15px;
      padding-top: 20px;
    }

    .spinner {
      display: none;
    }

    > .form {
      max-width: 700px;
      form {
        > .container {
          display: flex;
          > .row {
            margin: 0;
            + .submit {
              margin-top: 0;
              margin-left: 12px;
              .btn {
                padding: 4px 12px;
              }
              .alert {
                position: absolute;
                z-index: 1;
                top: -1px;
                padding: 5px 10px;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }
  > .row {
    height: 50%;
    &:first-child {
      padding-bottom: 45px;
    }
    &:last-child {
      padding-top: 45px;
    }
    > .col {
      > .header {
        padding-bottom: 5px;
        /*padding-bottom: 0;*/

        .right {
          .count {
            position: absolute;
            right: 0;
            bottom: -3px;
          }
        }
      }
      > .grid {
        height: 100% !important;
        max-height: 100% !important;
        position: relative;

        .fa-arrow-up {
          position: relative;
          top: 2px;
          font-size: 16px;
        }

        &.filtered {
          .alert {
            position: absolute;
            top: 37px;
            left: 0;
            height: auto;
            border: 0;
            background-color: transparent;
          }
        }
      }
    }
  }
}

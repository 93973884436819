@import "../variables";

.home {
  .card {
    // width: 200px;
    // border-color: #ccc;
    box-shadow: none;
    font-weight: 400;
    font-size: 16px;
    color: #666;
    text-align: center;
    transition: color 0.2s;
    -webkit-transition: color 0.2s;
    padding-top: 10px;
    padding-bottom: 10px;
    svg {
      padding-top: 10px;
      width: auto;
      height: 35px;
      color: #ccc;
    }
    &:hover, &:focus {
      cursor: pointer;
      background-color: #f8f9fa;
      color: $brand-color;
      border-color: #ccc;
      svg {
        color: #bbb;
      }
    }

    //&.crm {
    //  svg {
    //    color: orange;
    //  }
    //}
    //&.campaigns {
    //  svg {
    //    color: blue;
    //  }
    //}
    //&.routes {
    //  svg {
    //    color: red;
    //  }
    //}
  }
}

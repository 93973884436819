@import "variables";

.breadcrumb {
  justify-content: center;
  font-size: 10px;
  position: relative;
  top: -5px;
  margin-bottom: 0;

  @media (max-width: 991px) {
    top: -10px;
    margin-bottom: 5px;
  }

  .breadcrumb-item {
    + .breadcrumb-item {
      &:before {
        color: #ccc;
      }
    }

    &.active {
      color: #555;
    }
    a {
      color: #aaa;
      text-decoration: none;

      &:hover {
        color: $brand-color;
      }
    }

    &:last-child {
      a {
        color: #555;
        pointer-events: none;
      }
    }
  }
}

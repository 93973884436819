@import "../variables";

.index.route-sheet {
  height: 100%;
  &.ba {
    > .header {
      &:first-child {
        display: none;
      }
      justify-content: center;
      @include media-breakpoint-down(sm) {
        .label {
          margin-top: -15px;
          font-size: 10px;
          color: #555;
        }
      }
    }

    .detail {
      @include media-breakpoint-down(sm) {
        margin-top: -10px;
        background-color: transparent;
        padding: 10px 0 0 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
        border-top-color: #ddd;
        > .tabs {
          + .tab-content {
            margin-top: 4px;
          }
        }
        .header {
          .icons {
            top: -35px;
          }
        }
      }
      .tab-pane {
        &.photo {
          margin-top: 0;
          padding-top: 0;
          border-top: 0;
          > .row {
            > .col {
              &.venue {
                .header {
                  .label {
                    display: none !important;
                  }
                }
              }
              &.photo {
                .image {
                  .edit {
                    right: 10px !important;
                    @include media-breakpoint-down(sm) {
                      right: 18px !important;
                    }
                  }
                  .delete {
                    display: none !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.route-sheet.detail {
  @include media-breakpoint-down(sm) {
    height: 100%;
    .header {
      .icons {
        top: -45px;
        .fa {
          font-size: 17px;

          &.fa-street-view {
            display: none;
          }

          &.active {
            &:after {
              width: 25px;
              height: 25px;
              top: -4px;
              left: -6px;
            }
          }
        }
      }
    }
    .tabs {
      .nav-link {
        font-size: 16px;
      }
    }
  }

  .tab-pane {
    &.photo {
      margin-top: -4px;
      padding-top: 45px;
      border-top: 1px solid #e5e5e5;
      > .header {
        .label {
          display: none;
        }
        .icons {
          top: -80px;
        }
      }
      > .row {
        > .col {
          &.venue {
            min-height: 400px;
            .header {
              .label {
                font-size: 13px;
                color: #666;
                font-weight: 500;
                position: absolute;
                top: -27px;
                left: 0;
                display: block !important;
              }
            }

            &.map-showing {
              .header {
                .label {
                  left: 12px;
                }
              }
              > .map-street-showing {
                > .row {
                  > .col {
                    padding: 0;
                  }
                }
              }
            }

            @include media-breakpoint-down(sm) {
              &.photos-venue {
                //.grid {
                //  height: 350px !important;
                //}
              }
              &.map-showing {
                //.grid {
                //  max-height: 250px !important;
                //}
                .col {
                  display: block;
                  flex: none;
                  padding-right: 8px;
                  + .col {
                    padding-left: 12px;
                    //padding-top: 10px;
                    //max-height: 400px;
                  }
                }
              }
            }


            .ag-row {
              &.ag-row-hover {
                background-color: transparent;
                &:before {
                  background-color: transparent;
                }
                .ag-cell {
                  color: #000;
                }
              }
              &:hover {
                background-color: rgba(236, 240, 241, 0.5);
                .ag-cell {
                  // color: $brand-color;
                }
                @include media-breakpoint-down(sm) {
                  background-color: transparent;
                  .ag-cell {
                    color: #000;
                  }
                }
              }
            }

            .ag-row-selected {
              background-color: $hover-bg !important;
              &:before {
                background-color: $hover-bg !important;
              }
              .ag-cell {
                background-color: $hover-bg !important;
                color: #000;
                font-weight: 500;
                font-size: 12px;
              }
              &:hover {
                .ag-cell {
                  color: #000;
                }
              }
            }
            //.ag-row-pinned {
            //  background-color: $hover-bg !important;
            //  &:before {
            //    background-color: $hover-bg !important;
            //  }
            //  .ag-cell {
            //    background-color: $hover-bg !important;
            //    font-weight: 500;
            //    font-size: 12px;
            //  }
            //}
          }

          &.photo {
            position: relative;
            padding-right: 0;
            > .header {
              .label {
                font-size: 13px;
                color: #666;
                font-weight: 500;
                position: absolute;
                top: -27px;
                left: 0;
                display: block !important;
              }
            }

            > .spinner {
              top: 0;
            }

            .alert.empty {
              padding: 4px 7px;
              margin: 0;
              margin-left: -5px;
            }

            .list {
              padding-top: 10px;
              // height: 100%;
              overflow-y: auto;
              @include media-breakpoint-down(sm) {
                overflow-y: visible;
              }

              > div {
                + div {
                  margin-top: 17px;
                  padding-top: 22px;
                  border-top: 1px solid #d5d5d5;
                }

                .item {
                  font-size: 12px;
                  line-height: 1;
                  color: #666;
                  //margin-bottom: 8px;
                }
              }

              + .form {
                position: relative;
                margin-top: 20px;
                padding-top: 35px;
                padding-bottom: 15px;
                &:before {
                  position: absolute;
                  content: '';
                  background-color: #d5d5d5;
                  width: calc(100% - 15px);
                  height: 1px;
                  top: 0;
                  left: 0;
                }
                > form {
                  @include media-breakpoint-down(sm) {
                    .row {
                      display: flex !important;
                      .col {
                        padding: 0 6px;
                        &:nth-child(1) {
                          padding-left: 0;
                        }
                        &:nth-child(2) {
                          padding-right: 0;
                        }
                        &:nth-child(3) {
                          flex-basis: 100%;
                          padding-left: 0;
                          padding-right: 0;
                          padding-top: 15px;
                        }
                      }
                    }
                  }
                   > .container {
                     > .row {
                       margin-left: -25px;
                       .agreement {
                         &:hover {
                           cursor: pointer;
                           .linked {
                             color: #000;
                           }
                         }
                         &:not(.empty) {
                           &:hover {
                             cursor: default;
                           }
                           .form-control {
                             pointer-events: none;
                           }
                           .linked {
                             color: #000;
                           }
                         }
                       }
                     }
                   }
                }
              }
            }

            .photos {
              display: inline-flex;
              flex-wrap: wrap;
              margin-left: -8px;
              margin-top: -8px;

              $image-width: 185px;
              $image-height: 175px;
              .form {
                // padding-right: 10px;
                width: $image-width;
                margin: 5px;

                form {
                  > .container {
                    padding: 0;
                    > .row {
                      margin: 0;
                      position: relative;
                      > .col {
                        &:first-child {
                          padding-right: 0;
                          .image {
                            // min-height: 100px;
                            .form-outline {
                              height: 0;
                              ~ div {
                                position: relative;
                              }
                            }
                            .edit, .delete {
                              top: -3px;
                              &:after {
                                height: 25px;
                              }
                            }
                            .edit {
                              right: 35px;
                              &:after {
                                right: -13px;
                                width: 30px;
                              }
                            }
                            .delete {
                              right: 8px;
                            }

                            .spinner {
                              right: 0;
                              + div {
                                right: -3px;
                              }
                            }

                            @include media-breakpoint-down(sm) {
                              .edit, .delete {
                                top: 12px;
                                &:before {
                                  font-size: 15px;
                                }
                              }
                              .spinner {
                                right: 5px !important;
                                top: 27px !important;
                                + div {
                                  right: 3px !important;
                                  top: 24px;
                                }
                              }
                            }

                            img {
                              width: $image-width !important;
                              height: 130px !important;
                              object-fit: cover;
                              border-radius: 10px;
                              @include media-breakpoint-down(sm) {
                                margin-top: 15px;
                              }
                            }

                            .fa-image {
                              margin-top: -14px;
                              width: $image-width !important;
                              height: $image-height;
                              opacity: .8;

                              @media (max-width: 991px) {
                                margin-top: 0 !important;
                              }
                              + .spinner {
                                top: 50% !important;
                                right: 50% !important;
                                width: 30px;
                                height: 30px;
                                margin-top: -15px;
                                margin-right: -15px;
                                //opacity: .25;
                              }
                              + .fa-plus {
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%,-50%);
                              }

                              @include media-breakpoint-down(sm) {
                                + .fa-plus {
                                  width: 20px !important;
                                  height: 20px !important;
                                  transform: none !important;
                                  top: 35px !important;
                                  right: 25px !important;
                                  left: auto !important;
                                }
                                //&:after {
                                //  position: absolute;
                                //  top: 15px;
                                //  right: 8px;
                                //  content: '';
                                //  // background-color: rgba(249, 249, 249, 0.975);
                                //  background-color: red;
                                //  width: 22px;
                                //  height: 22px;
                                //  z-index: 1;
                                //}
                              }
                            }

                            &.readonly {
                              .form-outline {
                                ~ div {
                                  padding-right: 12px;
                                  //padding-top: 0;
                                }
                              }
                              //+ .checkbox {
                              //  display: none;
                              //}
                            }
                          }
                          .checkbox {
                            position: absolute;
                            z-index: 2;
                            right: 0;
                            bottom: -4px;
                            // width: 62px;

                            .btn {
                              padding: 3px 7px;
                              line-height: 1;
                            }

                            .form-outline {
                              margin-top: 10px;
                            }
                            .form-notch {
                              display: none;
                            }
                            .form-label {
                              padding: 0;
                              font-size: 11px;
                              line-height: 1.8;
                              color: #999;
                              transform: none;
                            }
                            .form-control {
                              background-color: transparent;
                              padding: 0;
                              text-align: right;
                              color: #333;
                            }
                          }
                        }
                      }

                      .info {
                        //position: absolute;
                        //z-index: 1;
                        //width: $image-width;
                        //bottom: 0;
                        //left: 0;
                        // padding: 5px;
                        // background-color: rgba(#fbfbfb,.95);
                        display: block;
                        margin-top: 8px;
                        margin-left: -8px;
                        .value {
                          // width: 150px;
                          white-space: normal;
                        }

                        @media (max-width: 991px) {
                          padding: 0;
                          border-top: 0;
                          .value {
                            width: auto;
                          }
                        }

                        @include media-breakpoint-down(sm) {
                          margin-left: 0;
                        }
                      }
                    }
                  }
                }
              }

              > .alert.empty {
                margin: 20px 0 0 8px;
                padding: 2px 7px;
                border: 0;
                background-color: #fff;
              }
            }

            //.agreement {
            //  display: inline-block;
            //  font-size: 12px;
            //  color: #666;
            //  transition: color 0.4s;
            //  -webkit-transition: color 0.4s;
            //  margin-left: -1px;
            //  .fa {
            //    padding-right: 5px;
            //    color: #999;
            //  }
            //  &:hover {
            //    color: $brand-color;
            //    cursor: pointer;
            //    .fa {
            //      color: $brand-color;
            //    }
            //  }
            //}
          }

          @include media-breakpoint-down(sm) {
            &.venue, &.photo {
              display: block;
              flex: none;
            }
            &.venue {
              min-height: auto;
            }
            &.photo {
              padding-top: 50px;
              > .header {
                > .label {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }

    .ag-row {
      &.has-photo {
        // background-color: rgba(green,.075);
        .ag-cell {
          color: #00a100 !important;
        }
      }
      &.has-refused {
        // background-color: rgba($brand-color,.06);
        .ag-cell {
          color: red !important;
        }
      }
    }
  }
}

@import "bootstrap/scss/bootstrap";
@import 'mdb-react-ui-kit/dist/css/mdb.min.css';
@import '@fortawesome/fontawesome-free/css/all.css';
@import 'ag-grid-community/dist/styles/ag-grid.css';
@import 'ag-grid-community/dist/styles/ag-theme-balham.css';
@import "variables";

html, body {
  width: 100%;
  height: 100%;
}
body {
  overflow-y: scroll;
  background-color: #fbfbfb;
  font-family: Montserrat,sans-serif;
}

#app {
  height: 100%;

  > .container {
    padding-top: 45px;
    transition: opacity 0.2s;
    -webkit-transition: opacity 0.2s;
    height: calc(100% - #{$nav-height} - #{$breadcrumb-height} - 55px);
    @include media-breakpoint-down(sm) {
      padding-top: 30px;
      height: calc(100% - #{$nav-height} - 55px);
    }
    position: relative;
  }
}

.spinner {
  position: absolute;
  top: 10%;
  left: 50%;
  opacity: .2;
  border-width: 2px;
  //transform: translate(-50%,-50%);

  + .row {
    .col {
      > .spinner {
        display: none;
      }
    }
  }

  ~ .tabs, ~ .tab-content {
    visibility: hidden;
    /*height: 0;*/
    /*display: none;*/
  }
}

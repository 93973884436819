@import "variables";

.alert {

  &.empty {
    font-size: 11px;
    line-height: 2;
    font-style: italic;
    color: #999;
    background-color: rgba(#fff, .45);
    padding: 0;
    margin: 5px 0 0 0;
  }

  &.grid {
    &.empty {
      border-color: $gray-400;
      padding: 5px 10px;
      /*border-style: dashed;*/
      /*border: 0;*/
      /*background-color: rgba($secondary, .025);*/
    }
  }

  &.form {
    &.empty {
      border: 0;
    }
  }
}

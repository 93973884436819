.view.profile {
  margin-top: 20px;
  display: flex;
  justify-content: center;

  form {
    width: 300px;
    .submit {
      text-align: center;
      .btn {
        width: 100%;
      }
    }
  }
  .alert {
    margin-top: 25px;
    font-size: 12px;
    font-style: italic;
    padding: 7px 14px;
    text-align: center;
  }
}


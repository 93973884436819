@import "variables";

.modal {
  padding: 0 15px !important;
  &.container-width {
    .modal-dialog {
      @each $breakpoint, $container-max-width in $container-max-widths {
        @include media-breakpoint-up($breakpoint) {
          max-width: $container-max-width !important;
        }
      }
    }
    .modal-body {
      > .form-outline {
        textarea {
          height: calc(100vh - 100px);
          font-size: 11px;
          line-height: 1.5;
          padding: 15px 11px;
        }
        .form-control {
          ~ .form-notch div {
            border-color: #bdbdbd;
            border-width: 1px;
          }
          ~ .form-label {
            color: #777;
          }
        }
      }
    }
  }
  &.full-screen {
    .modal-dialog {
      max-width: calc(100% - 20px) !important;
      .modal-header {
        .btn-close {
          display: block;
        }
      }
    }
  }
  &.confirm {
    .modal-body {
      font-size: 15px;
      font-weight: 300;
      > div + div:not(.detail) {
        padding-top: 10px;
      }
    }
    .modal-footer {
      justify-content: flex-start;
      .spinner {
        position: relative;
        top: auto;
        left: auto;
      }
    }
  }
  &.image {
    .modal-body {
      img {
        width: 100%;
        height: auto;
        &:hover {
          cursor:pointer;
          filter: brightness(95%);
        }
      }
      > .spinner {
        position: relative;
      }
    }
  }

  .modal-header {
    display: block;
    position: absolute;
    top: -3px;
    right: -14px;
    border: 0;
    padding: 0;
    .btn-close {
      display: none;
      padding: 0;
      background: none;
      opacity: .9;
      transition: opacity 0.2s;
      -webkit-transition: opacity 0.2s;
      &:hover, &:focus {
        opacity: 1;
        outline: 0;
        box-shadow: none;
      }
      &:after {
        color: #fff;
        position: absolute;
        top: -4px;
        right: -4px;
        content: "\f00d";
        font: normal normal normal 18px/1 FontAwesome;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        z-index: 2000;

        @include media-breakpoint-down(sm) {
          font-size: 22px !important;
        }
      }
    }
  }
}

body {
  > .modal {
    &:last-of-type {
      .modal-header {
        .btn-close {
          display: block;
        }
      }
    }
  }
}

@import "variables";

.list-group {
  margin-top: 20px;
  > .list-group-item {
    font-weight: 400;
    font-size: 16px;
    color: #666;
    text-align: center;
    transition: color 0.2s;
    -webkit-transition: color 0.2s;
    &:hover, &:focus {
      background-color: #f8f9fa;
      color: $brand-color;
    }
  }
}
